/* eslint-disable */
class Supplier {
  static getApiUrl = () => { return process.env.VUE_APP_API_ENDPOINT };

    static getSuppliers = async () => {
      const apiResponse = await fetch(
        `${this.getApiUrl()}/proveedor`
      );
      const suppliers = apiResponse.json();
      return suppliers;
    };
  
    static addSupplier = async (supplier) => {
      const apiResponse = await fetch(
        `${this.getApiUrl()}/proveedor/Agregar`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supplier),
        }
      );
      return apiResponse;
    };
  
    static deleteSupplier = async (IdProveedor) => {
      const apiResponse = await fetch(
        `${this.getApiUrl()}/proveedor/${IdProveedor}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      return apiResponse;
    };
  
    static updateSupplier = async (supplier) => {
      const apiResponse = await fetch(
        `${this.getApiUrl()}/proveedor/${supplier.IdProveedor}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(supplier),
        }
      );
      return apiResponse;
    };
  }
  export default Supplier;
  