/* eslint-disable */
class Order {
  static getApiUrl = () => { return process.env.VUE_APP_API_ENDPOINT };
  
  static getOrders = async () => {
    const apiResponse = await fetch(
      `${this.getApiUrl()}/pedido`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const products = apiResponse.json();
    return products;
  };

  static getOrdersByParameter = async (params)=>{
    const apiResponse = await fetch(
      `${this.getApiUrl()}/Pedido/ObtenerRegistros`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      }
    );
    const resp = apiResponse.json();
    return resp;
  }

  static getOrderDetails = async (id) => {
    const apiResponse = await fetch(
      `${this.getApiUrl()}/orders/getOrderDetails/${id}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const ordersDetail = apiResponse.json();
    return ordersDetail;
  };

  static getBranches = async () => {
    const apiResponse = await fetch(
      `${this.getApiUrl()}/Sucursal`
    );
    const branches = apiResponse.json();
    return branches;
  };

  static registerOrder = async (order) => {
    console.log(order);
    const apiResponse = await fetch(
      `${this.getApiUrl()}/Pedido/agregar`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(order),
      }
    );
    const resp = apiResponse.json();
    return resp;
  };

  static saveOrderDetails = async (details) => {
    console.log(details);
    const apiResponse = await fetch(
      `${this.getApiUrl()}/Pedido/agregarDetalle`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(details),
      }
    );
    return apiResponse;
  };

  static updateOrderDetails = async (idDetallePedido,orderDetails) => {
    const apiResponse = await fetch(
      `${this.getApiUrl()}/pedido/${idDetallePedido}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(orderDetails),
      }
    );
    const resp = apiResponse.json();
    return resp;
  };

  static deleteOrder = async (idDetallePedido) => {
    const apiResponse = await fetch(
      `${this.getApiUrl()}/pedido/${idDetallePedido}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return apiResponse;
  };

  //static updateOrder(order) {}
}

export default Order;
