var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"md":"3"}},[_c('v-combobox',{attrs:{"items":_vm.availableBranches,"item-text":"nombre","item-value":"idSucursal","chips":"","clearable":"","label":"Sucursales","multiple":"","solo":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
var index = ref.index;
return [(index === 0)?_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item.nombre))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedBranches.length - 1)+" más) ")]):_vm._e()]}}]),model:{value:(_vm.selectedBranches),callback:function ($$v) {_vm.selectedBranches=$$v},expression:"selectedBranches"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('v-combobox',{attrs:{"items":_vm.getAvailableProducts,"item-text":"Name","item-value":"ProductId","chips":"","clearable":"","label":"Productos","multiple":"","solo":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
var index = ref.index;
return [(index <= 1)?_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item.Name))])]):_vm._e(),(index === 2)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedProduct.length - 1)+" más) ")]):_vm._e()]}}]),model:{value:(_vm.selectedProduct),callback:function ($$v) {_vm.selectedProduct=$$v},expression:"selectedProduct"}})],1),_c('v-col',{attrs:{"md":"1"}},[_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary","elevation":"21"},on:{"click":function($event){return _vm.search()}}},[_vm._v("Aceptar")])],1),_c('v-col',{attrs:{"md":"1"}},[_c('v-btn',{staticClass:"mt-2",attrs:{"color":"success","elevation":"21","dark":""},on:{"click":function($event){return _vm.clean()}}},[_vm._v("Limpiar")])],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.stock,"headers":_vm.headers,"loading":_vm.loadingStock,"footer-props":{
          itemsPerPageText: 'Registros por Página',
          pageText: '{0} - {1} de {2}',
        }},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-progress-circular',{attrs:{"color":"blue","indeterminate":""}}),_c('div',[_vm._v("Cargando...")])]},proxy:true},{key:"body.append",fn:function(){return [_c('tr',[_c('td'),_c('td'),_c('td',[_c('span',[_vm._v("TOTAL: "+_vm._s(_vm.getTotal()))])]),_c('td',{attrs:{"colspan":"4"}})])]},proxy:true}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }