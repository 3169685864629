<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-row align="center" justify="center">
          <v-col md="6" lg="6"
            ><v-card-title
              ><v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field></v-card-title
          ></v-col>
          <v-col md="6" lg="6" class="d-flex justify-center"
            ><v-btn color="primary" @click="openAddDialog"
              >Agregar<v-icon light>mdi-plus</v-icon></v-btn
            ></v-col
          >
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="suppliers"
          :footer-props="{
            itemsPerPageText: 'Registros por Página',
            pageText: '{0} - {1} de {2}',
          }"
          :search="search"
        >
          <template v-slot:[`item.Delete`]="props">
            <v-btn @click="deleteSupplier(props.item)" class="action">
              <v-icon color="red">mdi-delete</v-icon>
              Eliminar
            </v-btn>
          </template>
          <template v-slot:[`item.Edit`]="props">
            <v-btn @click="openEditDialog(props.item)" class="action">
              <v-icon color="blue">mdi-circle-edit-outline</v-icon>
              Editar
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-row justify="center">
        <v-dialog v-model="addDialog" persistent max-width="400px">
          <v-card>
            <v-card-title class="justify-center">
              <span class="text-h5">{{ dialogText }}</span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="12" sm="12" xs="12">
                  <v-text-field v-model="newName" label="Nombre">
                  </v-text-field>
                </v-col>
                <v-col md="12" sm="12" xs="12">
                  <v-text-field
                    label="Descripcion"
                    v-model="newDescription"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn @click="closeModal" color="error">Cerrar</v-btn>
              <v-btn
                @click="addSupplier"
                color="primary"
                :disabled="newName.length > 0 ? false : true"
                >Guardar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
/* eslint-disable */
import store from "../store/index";
import { mapState } from "vuex";

export default {
  name: "supplier-list",
  data: () => {
    return {
      headers: [
        {
          text: "Nombre",
          value: "nombre",
          class: "white--text blue darken-3",
        },
        {
          text: "Descripcion",
          value: "informacion",
          class: "white--text blue darken-3",
        },
        {
          text: "Acción",
          value: "Edit",
          class: "white--text blue darken-3",
        },
        {
          text: "Acción",
          value: "Delete",
          class: "white--text blue darken-3",
        },
      ],
      dialogText: "",
      newDescription: "",
      newName: "",
      search: "",
      actionToExecute: "",
      addDialog: false,
      supplierId: "",
    };
  },
  methods: {
    closeModal() {
      this.addDialog = false;
      this.resetDialog();
    },

    resetDialog() {
      this.newName = "";
      this.newDescription = "";
    },

    openAddDialog() {
      this.dialogText = "Agregar";
      this.addDialog = true;
      this.actionToExecute = "Save";
    },

    openEditDialog(item) {
      this.newName = item.nombre;
      this.newDescription = item.informacion;
      this.actionToExecute = "Update";
      this.supplierId = item.idProveedor;
      this.dialogText = "Editar";
      this.addDialog = true;
    },

    addSupplier() {
      if (this.actionToExecute === "Save") {
        store
          .dispatch("addSupplier", {
            nombre: this.newName,
            informacion: this.newDescription
              ? this.newDescription
              : "Sin descripcion",
          })
          .then((response) => {
            if (response.status === 201) {
              store.dispatch("getSuppliers").then(() => {
                this.addDialog = false;
              });
            } else alert("Ocurrio un error al agregar el proveedor!");
          });
      }

      if (this.actionToExecute === "Update") {
        this.updateSupplier();
      }

      this.resetDialog();
    },

    updateSupplier() {
      store
        .dispatch("updateSupplier", {
          IdProveedor: this.supplierId,
          nombre: this.newName,
          informacion: this.newDescription,
        })
        .then((resp) => {
          if (resp.status === 201) store.dispatch("getSuppliers");
          this.addDialog = false;
        });
    },

    deleteSupplier(supplier) {
      this.$swal
        .fire({
          title: "Estas seguro que deseas eliminar este proveedor?",
          text: "Una vez eliminado no podras recuperarlo",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, deseo borrarlo!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            store.dispatch("deleteSupplier", supplier.idProveedor).then((resp) => {
              if (resp.status === 200) {
                this.$swal.fire(
                  "Eliminado!",
                  "El proveedor ha sido eliminado.",
                  "success"
                );
                store.dispatch("getSuppliers");
              } else {
                this.$swal.fire(
                  "Eroor!",
                  "Ha ocurrido un error al eliminar el proveedor.",
                  "error"
                );
              }
            });
          }
        });
    },

  },
  computed: {
    ...mapState(["suppliers"]),
  },
  created() {
    store.dispatch("getSuppliers");
  },
};
</script>
