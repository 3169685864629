<template>
  <v-app>
    <router-view />
  </v-app>
</template>
<!--
style="background-color: #250130; color: white"
<router-view/>
-->
<script>
/* eslint-disable */

export default {
  name: "App",
  components: {},
  data: () => ({}),
};
</script>
