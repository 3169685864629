<template>
  <v-container fluid>
    <form ref="formOrder">
      <v-row>
        <v-col sm="3" md="3">
          <v-select
            label="Sucursal"
            item-text="nombre"
            item-value="idSucursal"
            v-model="branch"
            :items="branches"
          >
          </v-select>
        </v-col>
        <v-col sm="3" md="3">
          <v-select
            label="Tipo captura"
            :items="['Entrada', 'Salida']"
            v-model="captureType"
          ></v-select>
        </v-col>
        <v-col sm="3" md="3">
          <v-menu
            v-model="menuDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="registerDate"
                label="Fecha"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :max="maxDate"
              :min="minDate"
              locale="es-MX"
              v-model="registerDate"
              @input="menuDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col sm="3" md="3">
          <v-select
            v-model="selectedSupplier"
            :items="suppliers"
            label="Proveedor"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="(p, i) in productsFiltered" :key="i" sm="4" md="4">
          <v-row>
            <v-col sm="2" md="2" class="pt-5">
              <v-img
                :src="getImage(p.Imagen)"
                max-height="125"
                width="100"
              ></v-img>
            </v-col>
            <v-col sm="3" md="3" class="pt-10">
              <span>{{ p.Nombre }}</span>
            </v-col>
            <v-col sm="3" md="3">
              <v-text-field v-model="p.amount" label="Cantidad" type="number">
              </v-text-field>
            </v-col>
            <v-col sm="4" md="4">
              <v-text-field v-model="p.observations" label="Observaciones">
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col lg="2" class="d-flex justify-center">
          <v-btn color="primary" @click="saveOrder">Guardar</v-btn>
        </v-col>
        <v-col lg="2" class="d-flex justify-center">
          <v-btn color="error" @click="reset">Cancelar</v-btn>
        </v-col>
      </v-row>
    </form>
  </v-container>
</template>
<script>
/* eslint-disable */
import Order from "../api/Order";
import store from "../store/index";
import { mapActions, mapState } from "vuex";
import "sweetalert2/dist/sweetalert2.min.css";
import moment from "moment";
import "moment/locale/es";
import "moment-timezone";

export default {
  name: "register-order",
  data: () => {
    return {
      branch: "",
      branches: [],
      pizzasCounter: "",
      pizzaObs: "",
      orderObs: "",
      captureType: "Entrada",
      registerDate: moment().tz("America/Mexico_City").format("YYYY-MM-DD"),
      menuDate: false,
      minDate: moment()
        .tz("America/Mexico_City")
        .subtract(10, "days")
        .format("YYYY-MM-DD"),
      maxDate: moment().tz("America/Mexico_City").format("YYYY-MM-DD"),
      selectedSupplier: "",
    };
  },
  computed: {
    ...mapState(["products"]),
    suppliers() {
      return store.state.suppliers.map((s) => s.nombre);
    },

    productsFiltered() {
      return this.products.filter((p) => p.Proveedor === this.selectedSupplier);
    },
  },

  methods: {
    ...mapActions(["addProduct"]),

    getImage(imageName) {
      let img = imageName ? imageName : "Notfound.png";
      return require(`@/assets/${img}`);
    },

    saveOrder() {
      const orderDetails = this.filterOrder();

      if (orderDetails.length > 0) {
        this.$swal
          .fire({
            title: "Confirmar Captura",
            text: "¿Desea guardar los datos capturados?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si",
            cancelButtonText: "No",
          })
          .then((result) => {
            if (result.isConfirmed) {
              const order = {
                idSucursal: this.branch,
                observaciones: this.orderObs
                  ? this.orderObs
                  : "Sin Observaciones",
                tipo: this.captureType,
                fecha: moment(
                  this.registerDate +
                    " " +
                    moment().tz("America/Mexico_City").format("HH:mm:ss")
                ).format("YYYY-MM-DD HH:mm:ss"),
              };

              store
                .dispatch("saveOrder", { order, orderDetails })
                .then((result) => {
                  if (result.status === 201) {
                    this.$swal.fire("Todo en orden", "Los datos han sido guardados", "success");
                    this.reset();
                  }
                });
            }
          });
      } else {
        this.$swal.fire(
          "Orden sin productos",
          "Ingrese productos al pedido",
          "warning"
        );
      }
    },

    filterOrder() {
      return this.products
        .filter((p) => p.amount)
        .map((product) => {
          return {
            idProducto: product.IdProduct,
            cantidad: parseFloat(product.amount),
            observaciones: product.observations
              ? product.observations
              : "Sin observaciones",
          };
        });
    },

    getBranches() {
      Order.getBranches()
        .then((response) => {
          this.branches = response;
          this.branch = response[0].idSucursal;
        })
        .catch((err) => {
          console.log(
            `An error has ocurred, please contact your administrator: ${err}`
          );
        });
    },

    reset() {
      this.pizzaObs = "";
      this.pizzasCounter = "";
      this.observations = "";
      this.products.forEach((product) => {
        product.amount = "";
        product.observations = "";
      });
      this.registerDate = moment()
        .tz("America/Mexico_City")
        .format("YYYY-MM-DD");
    },
  },
  mounted() {
    store.dispatch("getProducts").then(() => {
      this.getBranches();
      store.dispatch("getBranches");
    });

    store.dispatch("getSuppliers").then((resp) => {
      this.selectedSupplier = resp[0].nombre;
    });
  },
};
</script>
