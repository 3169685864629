<template>
  <v-container>
    <v-card>
      <v-row align="center" justify="center">
        <v-col md="6" lg="6"
          ><v-card-title
            ><v-text-field
              v-model="buscar"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field></v-card-title
        ></v-col>
        <v-col md="6" lg="6" class="d-flex justify-center"
          ><v-btn color="primary" @click="openAddDialog"
            >Agregar<v-icon light>mdi-plus</v-icon></v-btn
          ></v-col
        >
      </v-row>
      <v-card-text>
        <v-data-table
          disable-sort
          :items="products"
          :headers="headers"
          :search="buscar"
          :footer-props="{
            itemsPerPageText: 'Registros por Página',
            pageText: '{0} - {1} de {2}',
          }"
        >
          <template v-slot:[`item.Imagen`]="{ item }">
            <img
              :src="require(`@/assets/${item.Imagen}`)"
              style="width: 50px; height: 50px"
            />
          </template>
          <template v-slot:[`item.Delete`]="props">
            <v-btn @click="deleteProduct(props.item)" class="action">
              <v-icon color="red">mdi-delete</v-icon>
              Eliminar
            </v-btn>
          </template>
          <template v-slot:[`item.Edit`]="props">
            <v-btn @click="openEditDialog(props.item)" class="action">
              <v-icon color="blue">mdi-circle-edit-outline</v-icon>
              Editar
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-row justify="center">
        <v-dialog v-model="addDialog" persistent max-width="400px">
          <v-card>
            <v-card-title class="justify-center">
              <span class="text-h5">{{ dialogText }}</span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="12" sm="12" xs="12">
                  <v-text-field v-model="newProductName" label="Nombre">
                  </v-text-field>
                </v-col>
                <v-col md="12" sm="12" xs="12">
                  <v-select
                    v-model="newSupplier"
                    label="Proveedor"
                    :items="suppliers"
                  >
                  </v-select>
                </v-col>
                <v-col md="12" sm="12" xs="12">
                  <v-file-input
                    accept="image/png"
                    label="Registrar Imágen"
                    propend-icon="mdi-camera"
                    @change="getImageName"
                  >
                  </v-file-input>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn @click="closeModal" color="error">Cerrar</v-btn>
              <v-btn
                @click="addProduct"
                color="primary"
                :disabled="newProductName.length > 0 ? false : true"
                >Guardar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
/* eslint-disable */
import store from "../store/index";
import { mapState, mapActions } from "vuex";

export default {
  name: "product-list",
  props: {
    msg: String,
  },
  data() {
    return {
      headers: [
        {
          text: "ID Producto",
          value: "IdProduct",
          class: "white--text blue darken-3",
        },
        { text: "Nombre", value: "Nombre", class: "white--text blue darken-3" },
        { text: "Imagen", value: "Imagen", class: "white--text blue darken-3" },
        {
          text: "Proveedor",
          value: "Proveedor",
          class: "white--text blue darken-3",
        },
        { text: "", value: "Delete", class: "white--text blue darken-3" },
        { text: "", value: "Edit", class: "white--text blue darken-3" },
      ],
      addDialog: false,
      newProductName: "",
      newProductImage: "",
      idProduct: "",
      newSupplier: "Wrocco",
      buscar: "",
      dialogText: "",
      actionToExecute: "",
    };
  },
  methods: {
    ...mapActions(["getProducts", "addProduct"]),

    deleteProduct(product) {
      this.$swal
        .fire({
          title: "Estas seguro que deseas eliminar este producto?",
          text: "Una vez eliminado no podras recuperarlo",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, deseo borrarlo!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            store.dispatch("deleteProduct", product.IdProduct).then((resp) => {
              if (resp.status === 200) {
                this.$swal.fire(
                  "Eliminado!",
                  "El producto ha sido eliminado.",
                  "success"
                );
                store.dispatch("getProducts");
              } else {
                this.$swal.fire(
                  "Eroor!",
                  "Ha ocurrido un error al eliminar el producto.",
                  "error"
                );
              }
            });
          }
        });
    },
    editProduct(product) {
      alert("mensaje" + product.IdProducto);
    },
    addProduct() {
      if (this.actionToExecute === "Save") {
        store
          .dispatch("addProduct", {
            nombre: this.newProductName,
            precio: 0,
            rutaImagen: this.newProductImage
              ? this.newProductImage
              : "Notfound.png",
            proveedor: this.newSupplier,
          })
          .then((response) => {
            if (response.status === 201) {
              store.dispatch("getProducts").then(() => {
                this.addDialog = false;
              });
            } else alert("Ocurrio un error al agregar el producto!");
          });
      }

      if (this.actionToExecute === "Update") {
        this.updateProduct();
      }

      this.resetDialog();
    },
    getImageName(img) {
      this.newProductImage = img.name ? img.name : "Notfound.png";
    },
    openAddDialog() {
      this.dialogText = "Agregar";
      this.addDialog = true;
      this.actionToExecute = "Save";
    },
    openEditDialog(item) {
      this.idProduct = item.IdProduct;
      this.newProductName = item.Nombre;
      this.newSupplier = item.Proveedor;
      this.newProductImage = item.Imagen;
      this.dialogText = "Editar";
      this.addDialog = true;
      this.actionToExecute = "Update";
    },
    resetDialog() {
      this.newProductName = "";
      this.newSupplier = "Wrocco";
    },
    updateProduct() {
      store
        .dispatch("updateProduct", {
          idProducto: this.idProduct,
          nombre: this.newProductName,
          precio: 0,
          rutaImagen: this.newProductImage
            ? this.newProductImage
            : "Notfound.png",
          proveedor: this.newSupplier,
        })
        .then((resp) => {
          if (resp.status === 201) store.dispatch("getProducts");
          this.addDialog = false;
        });
    },
    closeModal() {
      this.addDialog = false;
      this.resetDialog();
    },
  },
  computed: {
    ...mapState(["products"]),
    suppliers() {
      return store.state.suppliers.map((s) => s.nombre);
    },
  },
  created() {
    store.dispatch("getProducts");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.label span {
  font-size: 16px;
  margin-top: 10px;
}
.label {
  padding-top: 45px;
}
.action {
  margin-right: 20px;
}
</style>
