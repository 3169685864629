/* eslint-disable */
class Auth {

  static getApiUrl = () => { return process.env.VUE_APP_API_ENDPOINT };

  static logIn = async (user) => {
    const apiResponse = await fetch(
      `${this.getApiUrl()}/login`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      }
    );

    const resp = apiResponse.json();
    return resp;
  };

  static getUsers = async () => {
    const apiResponse = await fetch(
      `${this.getApiUrl()}/login/obtenerUsuarios`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    const resp = apiResponse.json();
    return resp;
  };

  static updateUserPassword = async (user) => {
    const apiResponse = await fetch(
      `${this.getApiUrl()}/login/actualizaUsuario`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      }
    );

    const resp = apiResponse.json();
    return resp;
  };
}
export default Auth;
