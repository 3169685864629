/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import Product from "../api/Product";
import Order from "../api/Order";
import Auth from "../api/Auth";
import Supplier from "../api/Supplier";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    products: [],
    orders: [],
    suppliers: [],
    originalOrders: [],
    ordersDetail: [],
    branches: [],
    user: {},
    isUserLoggedIn: false,
    stock: [],
    originalStock: [],
    loadingOrders: true,
    loadingStock: true,
  },
  getters: {},
  mutations: {
    setProducts(state, products) {
      let productsTmp = products.map((p) => {
        return {
          IdProduct: p.idProducto,
          Nombre: p.nombre,
          Precio: p.precio,
          Imagen: p.rutaImagen,
          amount: "",
          observations: "",
          Proveedor: p.proveedor,
        };
      });

      state.products = productsTmp;
    },
    setOrders(state, orders) {
      state.orders = orders;
      state.loadingOrders = false;
    },
    setOrdersDetail(state, ordersDetail) {
      state.ordersDetail = ordersDetail;
    },
    setBranches(state, branches) {
      state.branches = branches;
    },
    setOriginalOrders(state, orders) {
      state.originalOrders = orders;
    },
    setUser(state, user) {
      state.user = user;
      state.isUserLoggedIn = true;
    },
    setStock(state, stock) {
      state.stock = stock;
      state.loadingStock = false;
    },
    setOriginalStock(state, stock) {
      state.originalStock = stock;
    },
    setSupplier(state, supliers) {
      state.suppliers = supliers;
    },
    setOrdersLoading(state,isLoading){
      state.loadingOrders = isLoading;
    } 
  },
  actions: {
    //Products actions
    getProducts({ commit }) {
      Product.getProducts()
        .then((response) => {
          commit("setProducts", response);
        })
        .catch((err) => {
          console.log(`Han error has ocurred: ${err}`);
        });
    },

    deleteProduct(state, productID) {
      return new Promise((a, r) => {
        Product.deleteProduct(productID)
          .then((response) => {
            a(response);
          })
          .catch((err) => {
            r(err);
            console.log(
              "Han error has ocurred, please contact to your administrator: " +
              err
            );
          });
      });
    },

    addProduct(state, product) {
      return new Promise((a, r) => {
        Product.addProduct(product)
          .then((response) => {
            a(response);
          })
          .catch((err) => {
            r(err);
            console.log(
              "Han error has ocurred, please contact to your administrator" +
              err
            );
          });
      });
    },

    updateProduct(state, product) {
      return new Promise((a, r) => {
        Product.updateProduct(product)
          .then((response) => {
            a(response);
          })
          .catch((err) => {
            r(err);
          });
      });
    },

    getBranches({ commit }) {
      Order.getBranches()
        .then((response) => {
          commit("setBranches", response);
        })
        .catch((err) => {
          console.log(
            `An error has ocurred, please contact your administrator: ${err}`
          );
        });
    },
    //Orders Actions
    saveOrder({ dispatch }, { order, orderDetails }) {
      let result;
      return new Promise((acepted, rejected) => {
        Order.registerOrder(order)
          .then((response) => {
            orderDetails.forEach((item) => {
              item.idPedido = response.idPedido;
            });
            return Order.saveOrderDetails(orderDetails);
          })
          .then((response) => {
            result = response;
            dispatch("getOrders");
          })
          .then(() => {
            acepted(result);
          })
          .catch((err) => {
            console.log(
              `An error has ocurred, please contact your administrator: ${err}`
            );
            rejected(err);
          });
      });
    },

    updateOrderDetails({ dispatch,commit }, { idPedido, orderToUpdate }) {
      let response = "";
      return new Promise((a, r) => {
        Order.updateOrderDetails(idPedido, orderToUpdate)
          .then((resp) => {
            response = resp;
            dispatch("getOrders");
            commit("setOrdersLoading",true);
          }).then(() => {
            commit("setOrdersLoading",false);
            a(response);
          })
          .catch((err) => {
            console.log(
              `An error has ocurred, please contact your administrator: ${err}`
            );
            r();
          });
      })
    },

    getOrders({ commit }) {
      Order.getOrders()
        .then((response) => {
          commit("setOrders", response);
          commit("setOriginalOrders", response);
        })
        .catch((err) => {
          console.log("Han error has ocurred: " + err);
        });
    },

    getOrderDetails({ commit }, id) {
      Order.getOrderDetails(id)
        .then((response) => {
          commit("setOrdersDetail", response);
        })
        .catch((err) => {
          console.log("Han error has ocurred: " + err);
        });
    },

    getOrdersByParameter({commit},params){
      commit("setOrdersLoading",true);
      Order.getOrdersByParameter(params).then((resp)=>{
        commit("setOrders", resp);
      })
    },

    deleteOrder({ dispatch }, idDetallePedido) {
      return new Promise((a, r) => {
        Order.deleteOrder(idDetallePedido)
          .then((response) => {
            if (response.status === 200) dispatch("getOrders");
            a(response);
          })
          .catch((err) => {
            console.log("Han error has ocurred: " + err);
            r(err);
          });
      });
    },

    logIn({ commit }, user) {
      return new Promise((a, r) => {
        Auth.logIn(user)
          .then((response) => {
            if (response.length > 0) {
              commit("setUser", response[0]);
              localStorage.setItem("user", JSON.stringify(response[0]));
            }
            a(response);
          })
          .catch((err) => {
            console.log("Ups! Ocurrio un error en el login");
            r(err);
          });
      });
    },

    logOut({ commit }) {
      commit("setUser", null);
      localStorage.clear();
    },

    getStock({ commit }) {
      Product.getStock()
        .then((resp) => {
          commit("setStock", resp);
          commit("setOriginalStock", resp);
        })
        .catch((err) => {
          console.log("error on get stock");
        });
    },

    //Supliers actions
    getSuppliers({ commit }) {
      return new Promise((a, r) => {
        Supplier.getSuppliers().then((resp) => {
          if (resp.length > 0) {
            commit("setSupplier", resp);
            a(resp)
          }
        }).catch((err) => {
          console.log(err);
          r(err);
        })
      })
    },

    addSupplier(state, supplier) {
      return new Promise((a, r) => {
        Supplier.addSupplier(supplier).then((resp) => {
          a(resp);
        }).catch(err => {
          r(err);
        })
      })
    },

    updateSupplier(state, supplier) {
      return new Promise((a, r) => {
        Supplier.updateSupplier(supplier).then((resp) => {
          a(resp);
        }).catch(err => {
          r(err);
        })
      })
    },

    deleteSupplier(state, supplierId) {
      return new Promise((a, r) => {
        Supplier.deleteSupplier(supplierId).then((resp) => {
          a(resp);
        }).catch(err => {
          r(err);
        })
      })
    }


  },
});
