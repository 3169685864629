/* eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import RegisterView from "../views/RegisterView.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/inicio",
    name: "home",
    component: HomeView,
    meta: {
      needsAuth: true
    },
  },
  {
    path: "/registro",
    name: "register",
    component: RegisterView,
    meta: {
      needsAuth: true
    }
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/",
    name: "login",
    component: LoginView,
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

let isUserLoggedIn = () => {
  if (localStorage.getItem('user'))
    return true;
  else
    return false;
}

router.beforeEach((to, from, next) => {
  if (to.meta.needsAuth) {
    if (isUserLoggedIn()) {
      let userRol = JSON.parse(localStorage.getItem('user'));
      console.log(from.name);
      if ((userRol.rol === "Admin" && from.name === "login")
        || (userRol.rol === "capturador" && from.name === "login")) {
        next();
      }
      else {
        next();
      }
    } else {
      next('/login');
    }
  } else {
    next();
  }

})
export default router;
