<template>
  <v-container
    class="contenedor"
    fluid
    fill-height
    style="background-color: #f5f7f8"
    align="center"
  >
    <v-row justify="center" align="center">
      <v-col sm="4" md="4" lg="4" xl="4"> <Login></Login></v-col>
    </v-row>
  </v-container>
</template>
<script>
/* eslint-disable */
import Login from "./../components/Login.vue";

export default {
    components: { Login },
    data: () => ({}),
};
</script>

<style scoped>
.contenedor {
    background: rgb(2, 0, 36);
    background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(9, 9, 121, 1) 40%,
        rgba(0, 212, 255, 1) 100%
    );
}
</style>