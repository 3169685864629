<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col md="3"
            ><v-combobox
              v-model="selectedBranches"
              :items="availableBranches"
              item-text="nombre"
              item-value="idSucursal"
              chips
              clearable
              label="Sucursales"
              multiple
              solo
            >
              <template
                v-slot:selection="{ attrs, item, select, selected, index }"
              >
                <v-chip
                  v-if="index === 0"
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove(item)"
                >
                  <strong>{{ item.nombre }}</strong>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ selectedBranches.length - 1 }} más)
                </span>
              </template>
            </v-combobox>
          </v-col>
          <v-col md="3">
            <v-combobox
              v-model="selectedProduct"
              :items="getAvailableProducts"
              item-text="Name"
              item-value="ProductId"
              chips
              clearable
              label="Productos"
              multiple
              solo
            >
              <template
                v-slot:selection="{ attrs, item, select, selected, index }"
              >
                <v-chip
                  v-if="index <= 1"
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove(item)"
                >
                  <strong>{{ item.Name }}</strong>
                </v-chip>
                <span v-if="index === 2" class="grey--text text-caption">
                  (+{{ selectedProduct.length - 1 }} más)
                </span>
              </template>
            </v-combobox>
          </v-col>
          <v-col md="1">
            <v-btn color="primary" elevation="21" class="mt-2" @click="search()"
              >Aceptar</v-btn
            >
          </v-col>
          <v-col md="1">
            <v-btn
              color="success"
              elevation="21"
              dark
              class="mt-2"
              @click="clean()"
              >Limpiar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text
        ><v-data-table
          :items="stock"
          :headers="headers"
          :loading="loadingStock"
          :footer-props="{
            itemsPerPageText: 'Registros por Página',
            pageText: '{0} - {1} de {2}',
          }"
        >
          <template v-slot:loading>
            <v-progress-circular
              color="blue"
              indeterminate
            ></v-progress-circular>
            <div>Cargando...</div>
          </template>
          <template v-slot:[`body.append`]>
            <tr>
              <td></td>
              <td></td>
              <td>
                <span>TOTAL: {{ getTotal() }}</span>
              </td>
              <td colspan="4"></td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import store from "../store/index";
import Order from "../api/Order";

export default {
  name: "stock-component",
  components: {},
  data: () => {
    return {
      headers: [
        {
          text: "SUCURSAL",
          value: "sucursal",
          class: "white--text blue darken-3",
        },
        {
          text: "PRODUCTO",
          value: "producto",
          class: "white--text blue darken-3",
        },
        {
          text: "Existencia",
          value: "existencia",
          class: "white--text blue darken-3",
        },
      ],
      branch: "",
      branches: [],
      selectedProduct: [],
      selectedBranches: [],
      availableBranches: [],
      stockTmp: [],
      cantidad: "",
      producto: "",
      sucursal: "",
    };
  },
  methods: {
    search() {
      if (this.selectedBranches.length > 0) {
        this.stockTmp = this.stock.filter((stock) => {
          return this.selectedBranches.some(
            (branch) => branch.nombre === stock.sucursal
          );
        });
      } else {
        this.stockTmp = this.stock;
      }

      if (this.selectedProduct.length > 0) {
        this.stockTmp = this.stockTmp.filter((stock) => {
          return this.selectedProduct.some(
            (product) => product.Name === stock.producto
          );
        });
      }
      store.commit("setStock", this.stockTmp);
    },

    getTotal() {
      if (this.stockTmp.length > 0) {
        return this.stockTmp.reduce((acc, curr) => acc + curr.existencia, 0);
      }
      if (this.stock.length > 0) {
        return this.stock.reduce((acc, curr) => acc + curr.existencia, 0);
      }
    },

    getBranches() {
      Order.getBranches()
        .then((response) => {
          /*this.availableBranches = [
            ...response,
            { idSucursal: 0, nombre: "TODAS" },
          ];*/
          this.availableBranches = response;
        })
        .catch((err) => {
          console.log(
            `An error has ocurred, please contact your administrator: ${err}`
          );
        });
    },

    remove(item) {
      this.selectedProduct.splice(this.selectedProduct.indexOf(item), 1);
      this.selectedBranches.splice(this.selectedBranches.indexOf(item), 1);
    },

    clean() {
      this.firstDate = "";
      this.secondDate = "";
      this.selectedProduct = [];
      this.selectedBranches = [];
      this.ordersTmp = [];
      store.commit("setStock", this.originalStock);
    },
  },
  mounted() {
    store.dispatch("getStock");
    this.getBranches();
  },
  computed: {
    ...mapState(["stock", "products", "originalStock", "loadingStock"]),

    getAvailableProducts() {
      let products = this.products.map((p) => {
        return { ProductID: p.IdProducto, Name: p.Nombre };
      });
      return products;
    },
  },
};
</script>
<style scoped>
.label {
  margin-top: 20px;
}
</style>
