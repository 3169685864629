<template>
  <v-dialog :value="isVisible" persistent max-width="400">
    <v-card>
      <v-card-title class="justify-center">Actualizar Contraseña</v-card-title>
      <v-card-text>
        <v-row>
          <v-col sm="12" md="12">
            <v-row>
              <v-col sm="4" md="4" class="mt-7">
                <label>Rol:</label>
              </v-col>
              <v-col sm="8" md="8">
                <v-select
                  :items="userItems"
                  item-text="rol"
                  item-value="nombre"
                  v-model="selectedRol"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col sm="12" md="12">
            <v-row>
              <v-col sm="4" md="4" class="mt-7"
                ><label>Contraseña: </label></v-col
              >
              <v-col sm="8" md="8">
                <v-text-field type="password" v-model="password">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col sm="12" md="12">
            <v-row>
              <v-col sm="4" md="4" class="mt-5">
                <label>Confirmar Contraseña</label>
              </v-col>
              <v-col sm="8" md="8">
                <v-text-field
                  type="password"
                  v-model="confirmPassword"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col sm="12" md="12" class="d-flex justify-center">
            <span class="msjError" v-if="showErrorMsj"
              >Las contraseñas no coindicen</span
            >
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="success" @click="confirmChanges">Guardar</v-btn>
        <v-btn color="error" @click="exit">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
/* eslint-disable */
import store from "../store/AuthStore";
import { mapState } from "vuex";
//import router from "../router/index";
export default {
  name: "password-edit-modal",
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    userItems: [],
    user: "",
    password: "default",
    confirmPassword: "default",
    selectedRol: "",
    showErrorMsj: false,
  }),
  methods: {
    confirmChanges() {
      if (this.password === this.confirmPassword) {
        let userTmp = {
          nombre: this.selectedRol,
          contrasena: this.password,
          rol: "",
        };
        store.dispatch("updateUserPassword", userTmp).then((resp) => {
          this.exit();
        });
      }else{
        this.displayErrorMsj();
      }
    },
    exit() {
      this.$emit("closeModal");
    },
    displayErrorMsj() {
      this.showErrorMsj = true;
      setTimeout(() => {
        this.showErrorMsj = false;
      }, 3000);
    },
  },
  computed: {
    ...mapState(["users"]),
  },
  mounted() {
    store.dispatch("getUsers").then((users) => {
      console.log("getUsers", users);
      this.userItems = users;
      this.selectedRol = users[0].nombre;
    });
  },
};
</script>
<style scoped>
.msjError {
  color: red;
}
</style>