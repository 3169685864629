/* eslint-disable */
class Product {
  static getApiUrl = () => { return process.env.VUE_APP_API_ENDPOINT };
  
  static getProducts = async () => {
    const apiResponse = await fetch(
      `${this.getApiUrl()}/Producto`
    );
    const products = apiResponse.json();
    return products;
  };

  static addProduct = async (product) => {
    const apiResponse = await fetch(
      `${this.getApiUrl()}/Producto/agregarProducto`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(product),
      }
    );
    return apiResponse;
  };

  static deleteProduct = async (productId) => {
    const apiResponse = await fetch(
      `${this.getApiUrl()}/Producto/${productId}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return apiResponse;
  };

  static updateProduct = async (product) => {
    const apiResponse = await fetch(
      `${this.getApiUrl()}/Producto/${product.idProducto}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(product),
      }
    );
    return apiResponse;
  };

  static getStock = async () => {
    const apiResponse = await fetch(
      `${this.getApiUrl()}/pedido/existencia`
    );
    const products = apiResponse.json();
    return products;
  };
}
export default Product;
