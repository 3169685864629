<template>
  <v-card>
    <v-card-text class="card">
      <v-row>
        <v-col xs="12" sm="12" md="12" cols="12" class="d-flex justify-center"
          ><span class="login">INICIO DE SESIÓN</span></v-col
        >
        <v-col xs="12" sm="12" md="12" cols="12" class="d-flex justify-center">
          <v-text-field
            label="Usuario"
            v-model="user"
            prepend-icon="mdi-account-lock"
          ></v-text-field
        ></v-col>
        <v-col xs="12" sm="12" md="12" cols="12" class="d-flex justify-center">
          <v-text-field
            v-model="password"
            label="Contraseña"
            prepend-icon="mdi-eye"
            type="password"
            @keydown="enterPressed($event)"
          ></v-text-field
        ></v-col>
        <v-col xs="12" sm="12" md="12" cols="12" class="d-flex justify-center">
          <span v-if="showError" class="errorMsj"
            >Usuario o contraseña incorrectos</span
          >
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex justify-center pt-0">
      <v-col cols="12" class="d-flex justify-center">
        <v-btn color="primary" x-large block @click="userValidation"
          >Aceptar</v-btn
        >
      </v-col>
    </v-card-actions>
  </v-card>
</template>
<script>
/* eslint-disable */
import store from "../store/index";
import router from "../router/index";

export default {
  name: "login-box",
  data: () => ({
    user: "",
    password: "",
    showError: false,
  }),
  methods: {
    userValidation() {
      let userLogIng = {
        nombre: this.user,
        contrasena: this.password,
        rol: "",
      };
      store.dispatch("logIn", userLogIng).then((resp) => {
        let user = resp[0] ? resp[0] : "";
        if (user.rol === "Admin") router.push("/inicio");
        if (user.rol === "capturador") router.push("/registro");
        else this.showAuthenticationError();
      });
    },

    showAuthenticationError() {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 3000);
    },

    enterPressed(e) {
      if (e.keyCode === 13) this.userValidation();
    },
    
  },
  computed: {},
};
</script>
<style scoped>
.errorMsj {
  color: red;
}

.card{
  font-family:Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.login{
  font-size: 20px;
  font-weight: bold;
}
</style>