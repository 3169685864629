/* eslint-disable */
import Vuex from "vuex";
import Auth from "../api/Auth";
import Vue from "vue";

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    users: [],
  },
  getters: {},
  mutations: {
    setUsers(state, users) {
      state.users = users;
    },
  },
  actions: {
    logIn({ commit }, user) {
      return new Promise((a, r) => {
        Auth.logIn(user).then((response) => {
          if (response.length > 0) {
            commit("setUser", response[0]);
            localStorage.setItem("user", JSON.stringify(response[0]));
          }
          a(response);
        }).catch((err) => {
          console.log("Ups! Ocurrio un error en el login");
          r(err);
        })
      })
    },

    logOut({ commit }) {
      commit("setUser", null);
      localStorage.clear();
    },

    getUsers({ commit }) {
      return new Promise((a, r) => {
        Auth.getUsers().then((response) => {
          commit("setUsers", response);
          a(response);
        }).catch((err) => {
          console.log('An error ocurred on get users');
          r(err);
        })
      })
    },

    updateUserPassword(state, user) {
      console.log("user", user);
      return new Promise((a, r) => {
        Auth.updateUserPassword(user).then((response) => {
          a(response)
        })
      }).catch((err) => {
        r(err);
      })

    }
  },
});
export default store
