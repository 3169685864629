<template>
  <v-container fluid>
    <v-row style="background-color: #000017">
      <v-col md="10" sm="10">
        <v-tabs
          v-model="tab"
          background-color="#000017"
          centered
          dark
          icons-and-text
        >
          <v-tab v-for="item in items" :key="item.tab" style="color: orange">
            <v-icon>
              {{ item.icon }}
            </v-icon>
            {{ item.tab }}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col md="2" sm="2" class="pt-5">
        <v-btn fab color="warning" dark @click="logOut">Salir</v-btn>
      </v-col>
    </v-row>

    <v-tabs-items v-model="tab" class="mt-5">
      <v-tab-item v-for="item in items" :key="item.tab">
        <v-card flat>
          <component :is="item.content"></component>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import RegisterOrder from "./../components/RegisterOrder.vue";
import EditOrder from "./../components/OrdersHistory.vue";
import store from "../store/index";
import router from "../router/index";
export default {
  components: { RegisterOrder, EditOrder },

  data: () => ({
    tab: null,
    items: [
      {
        tab: "Registrar",
        content: "RegisterOrder",
        icon: "mdi-plus-box",
      },
      {
        tab: "Historial",
        content: "EditOrder",
        icon: "mdi-plus",
      },
    ],
    userItems: [{ title: "Salir" }, { title: "Configuración" }],
  }),
  methods: {
    logOut() {
      store.dispatch("logOut").then(() => {
        router.push("/login");
      });
    },
  },
};
</script>
