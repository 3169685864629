<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col md="2"
            ><v-combobox
              v-model="selectedBranches"
              :items="availableBranches"
              item-text="nombre"
              item-value="idSucursal"
              chips
              clearable
              label="Sucursales"
              multiple
              solo
            >
              <template
                v-slot:selection="{ attrs, item, select, selected, index }"
              >
                <v-chip
                  v-if="index === 0"
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove(item)"
                >
                  <strong>{{ item.nombre }}</strong>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ selectedBranches.length - 1 }} más)
                </span>
              </template>
            </v-combobox>
          </v-col>
          <v-col md="2">
            <v-menu
              v-model="menuDateFrom"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="firstDate"
                  label="Fecha Inicio"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="es-MX"
                v-model="firstDate"
                @input="menuDateFrom = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="2">
            <v-menu
              v-model="menuDateTo"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="secondDate"
                  label="Fecha Fin"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="es-MX"
                v-model="secondDate"
                @input="menuDateTo = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="2">
            <v-combobox
              v-model="selectedProduct"
              :items="productsTmp"
              item-text="Name"
              item-value="ProductID"
              chips
              clearable
              label="Productos"
              multiple
              solo
            >
              <template
                v-slot:selection="{ attrs, item, select, selected, index }"
              >
                <v-chip
                  v-if="index <= 1"
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove(item)"
                >
                  <strong>{{ item.Name }}</strong>
                </v-chip>
                <span v-if="index === 2" class="grey--text text-caption">
                  (+{{ selectedProduct.length - 1 }} más)
                </span>
              </template>
            </v-combobox>
          </v-col>
          <v-col md="2">
            <v-select
              v-model="selectedSupplier"
              :items="suppliers"
              item-text="nombre"
              item-value="nombre"
              label="Proveedores"
            ></v-select>
          </v-col>
          <v-col md="1">
            <v-btn color="primary" elevation="21" class="mt-2" @click="search()"
              >Aceptar</v-btn
            >
          </v-col>
          <v-col md="1">
            <v-btn
              color="success"
              elevation="21"
              dark
              class="mt-2"
              @click="clean()"
              >Limpiar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text
        ><v-data-table
          :items="orders"
          :headers="headers"
          :loading="loadingOrders"
          :footer-props="{
            itemsPerPageText: 'Registros por Página',
            pageText: '{0} - {1} de {2}',
          }"
        >
          <template v-slot:loading>
            <v-progress-circular
              color="blue"
              indeterminate
            ></v-progress-circular>
            <div>Cargando...</div>
          </template>
          <template v-slot:[`item.fecha`]="{ item }">
            <span>{{ dateFormat(item.fecha) }}</span>
          </template>
          <template v-slot:[`item.Delete`]="props">
            <v-btn @click="deleteOrder(props.item)" class="action">
              <v-icon color="red">mdi-delete</v-icon>
              Eliminar
            </v-btn>
          </template>
          <template v-slot:[`item.Edit`]="props">
            <v-btn @click.stop="editOrder(props.item)" class="action">
              <v-icon color="blue">mdi-clipboard-list</v-icon>
              Editar
            </v-btn>
          </template>
          <template v-slot:[`body.append`]>
            <tr>
              <td></td>
              <td></td>
              <td>
                <span>TOTAL: {{ Number(getTotal()).toFixed(2) }}</span>
              </td>
              <td colspan="4"></td>
            </tr>
          </template>
        </v-data-table>
        <v-dialog v-model="showEditaDialog" max-width="400">
          <v-card>
            <v-card-title class="d-flex justify-center"
              >Editar registro
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col sm="12" md="12" class="d-flex justify-center"
                  ><v-text-field
                    v-model="sucursal"
                    label="SUCURSAL"
                    readonly
                  ></v-text-field
                ></v-col>
                <v-col sm="12" md="12" class="d-flex justify-center"
                  ><v-text-field
                    readonly
                    v-model="producto"
                    label="PRODUCTO"
                  ></v-text-field
                ></v-col>
                <v-col sm="12" md="12" class="d-flex justify-center"
                  ><v-text-field
                    type="number"
                    v-model="cantidad"
                    label="CANTIDAD"
                  ></v-text-field
                ></v-col>
                <v-col sm="12" md="12" class="d-flex justify-center"
                  ><v-text-field
                    label="OBSERVACIONES"
                    v-model="observaciones"
                  ></v-text-field
                ></v-col>
                <v-col sm="12" md="12" class="d-flex justify-center"
                  ><v-text-field
                    v-model="fecha"
                    label="FECHA"
                    readonly
                  ></v-text-field
                ></v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-btn color="primary" @click="saveDetails" :loading="isLoading"
                >Guardar</v-btn
              >
              <v-btn color="error" @click="showEditaDialog = false"
                >Cerrar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import "sweetalert2/dist/sweetalert2.min.css";
import { mapState, mapActions } from "vuex";
import store from "../store/index";
import Order from "../api/Order";
import moment from "moment";
import "moment/locale/es";

export default {
  name: "orders-history",
  data: () => {
    return {
      headers: [
        {
          text: "SUCURSAL",
          value: "sucursal",
          class: "white--text blue darken-3",
        },
        {
          text: "PRODUCTO",
          value: "producto",
          class: "white--text blue darken-3",
        },
        {
          text: "CANTIDAD",
          value: "cantidad",
          class: "white--text blue darken-3",
        },
        {
          text: "FECHA DE REGISTRO",
          value: "fecha",
          class: "white--text blue darken-3",
        },
        {
          text: "TIPO",
          value: "tipo",
          class: "white--text blue darken-3",
        },
        {
          text: "OBSERVACIONES",
          value: "observaciones",
          class: "white--text blue darken-3",
        },
        {
          text: "PROVEEDOR",
          value: "proveedor",
          class: "white--text blue darken-3",
        },
        {
          text: "ACCIÓN",
          value: "Delete",
          class: "white--text blue darken-3",
        },
        {
          text: "ACCIÓN",
          value: "Edit",
          class: "white--text blue darken-3",
        },
        /*{
          text: "ACCIÓN",
          value: "Details",
          class: "white--text blue darken-3",
        },*/
      ],
      editDialog: false,
      branch: "",
      branches: [],
      menuDateFrom: false,
      menuDateTo: false,
      firstDate: "",
      secondDate: "",
      selectedProduct: [],
      selectedBranches: [],
      availableBranches: [],
      ordersTmp: [],
      showEditaDialog: false,
      isLoading: false,
      cantidad: "",
      fecha: "",
      idDetallePedido: "",
      observaciones: "",
      producto: "",
      sucursal: "",
      selectedSupplier: "",
      productsTmp: [],
      suppliersTmp: [],
      filter: {
        branches: [],
        startDate: "",
        endDate: "",
        products: [],
        suplier: "",
      },
    };
  },
  methods: {
    ...mapActions(["getOrders", "getOrderDetails"]),

    dateFormat(date) {
      let dateTmp = new Date(date);
      return moment(dateTmp, "DD MM YYYY hh:mm:ss").format("LLLL");
    },

    deleteOrder(item) {
      this.$swal
        .fire({
          title: "Estas seguro que desea eliminar este registro?",
          text: "Una vez eliminado no podras recuperarlo",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, deseo borrarlo!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            store.dispatch("deleteOrder", item.idDetallePedido).then((resp) => {
              if (resp.status === 200)
                this.$swal.fire(
                  "Eliminado!",
                  "El pedido ha sido eliminado.",
                  "success"
                );
            });
          }
        });
    },
    search() {
      if (this.selectedBranches.length > 0) {
        let branches = this.selectedBranches.map((b) => b.idSucursal);
        this.filter.branches = [...branches];
      }

      if (this.selectedProduct.length > 0) {
        let products = this.selectedProduct.map((p) => p.ProductID);
        this.filter.products = [...products];
      }

      let beginDate = this.firstDate
        ? moment(this.firstDate).format("YYYY-MM-DD")
        : "";
      let endDate = this.secondDate
        ? moment(this.secondDate).format("YYYY-MM-DD")
        : "";

      this.filter.startDate = beginDate;
      this.filter.endDate = endDate;
      this.filter.suplier = this.selectedSupplier;

      store.dispatch("getOrdersByParameter", this.filter);
      //store.commit("setOrders", this.ordersTmp);
      this.filter = {
        branches: [],
        startDate: "",
        endDate: "",
        products: [],
        suplier: "",
      };
    },

    saveDetails() {
      let orderToUpdate = {
        cantidad: this.cantidad,
        observaciones: this.observaciones,
      };

      let idPedido = this.idDetallePedido;
      this.isLoading = true;
      store
        .dispatch("updateOrderDetails", { idPedido, orderToUpdate })
        .then(() => {
          this.isLoading = false;
          this.showEditaDialog = false;
        });
    },

    editOrder(item) {
      this.idDetallePedido = item.idDetallePedido;
      this.cantidad = item.cantidad;
      this.observaciones = item.observaciones;
      this.fecha = moment(item.fecha).format("YYYY-MM-DD");
      this.sucursal = item.sucursal;
      this.producto = item.producto;
      this.showEditaDialog = true;
    },

    getTotal() {
      if (this.ordersTmp.length > 0)
        return this.ordersTmp.reduce((acc, curr) => acc + curr.cantidad, 0);
      else return this.orders.reduce((acc, curr) => acc + curr.cantidad, 0);
    },

    getBranches() {
      Order.getBranches()
        .then((response) => {
          this.availableBranches = response;
        })
        .catch((err) => {
          console.log(
            `An error has ocurred, please contact your administrator: ${err}`
          );
        });
    },

    remove(item) {
      this.selectedProduct.splice(this.selectedProduct.indexOf(item), 1);
      this.selectedBranches.splice(this.selectedBranches.indexOf(item), 1);
    },

    clean() {
      this.firstDate = "";
      this.secondDate = "";
      this.selectedProduct = [];
      this.selectedBranches = [];
      this.ordersTmp = [];
      this.selectedSupplier = "";
      this.mapProducts();
      //store.commit("setOrders", this.originalOrders);
      store.loadingOrders = true;
      store.dispatch("getOrders");
    },

    mapProducts() {
      this.productsTmp = this.products.map((p) => {
        return { ProductID: p.IdProduct, Name: p.Nombre };
      });
    },
  },
  mounted() {
    store.dispatch("getOrders");
    this.getBranches();
  },
  created() {
    this.mapProducts();
  },
  computed: {
    ...mapState([
      "orders",
      "ordersDetail",
      "products",
      "originalOrders",
      "loadingOrders",
      "suppliers",
    ]),

    /*getAvailableProducts() {
      let products = this.products.map((p) => {
        return { ProductID: p.IdProducto, Name: p.Nombre };
      });
      return products;
    },*/
  },
  watch: {
    selectedSupplier(newSup) {
      if (newSup) {
        this.productsTmp = this.products
          .filter((p) => p.Proveedor === newSup)
          .map((pm) => {
            return { ProductID: pm.IdProduct, Name: pm.Nombre };
          });
      }
    },
  },
};
</script>
<style scoped>
.label {
  margin-top: 20px;
}
</style>
