<template>
  <v-card>
    <v-card-title
      class="text-center justify-center py-6"
      style="background-color: #000017; color: orange"
    >
      <h1>WROCCO WROWERS</h1>
      <v-tabs
        v-model="tab"
        background-color="#000017"
        centered
        dark
        icons-and-text
      >
        <v-tab v-for="item in items" :key="item.tab" style="color: orange">
          <v-icon>
            {{ item.icon }}
          </v-icon>
          {{ item.tab }}
        </v-tab>
        <div class="text-center">
          <v-menu>
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    color="warning"
                    fab
                    dark
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                  >
                    <v-icon>mdi-view-list</v-icon>
                  </v-btn>
                </template>
                <span>Perfil</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in userItems" :key="index">
                <v-list-item-title @click="menuListClick(item)">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-tabs>
    </v-card-title>
    <v-card-text style="height: 800px; overflow-y: scroll">
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.tab">
          <v-card flat>
            <component :is="item.content"></component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <PasswordEdit :isVisible="isModalVisible" @closeModal="closeModal" />
    </v-card-text>
  </v-card>
</template>

<script>
import ProductList from "./../components/ProductList.vue";
import RegisterOrder from "./../components/RegisterOrder.vue";
import OrdersHistory from "./../components/OrdersHistory.vue";
import Stock from "../components/Stock.vue";
import store from "../store/index";
import router from "../router/index";
import PasswordEdit from "./../components/PasswordEdit.vue";
import Supplier from "./../components/Supplier.vue";

export default {
  components: {
    ProductList,
    RegisterOrder,
    OrdersHistory,
    Stock,
    PasswordEdit,
    Supplier,
  },

  data: () => ({
    tab: null,
    items: [
      {
        tab: "Registrar",
        content: "RegisterOrder",
        icon: "mdi-plus-box",
      },
      {
        tab: "Existencia",
        content: "Stock",
        icon: "mdi-plus",
      },
      {
        tab: "Historial E/S",
        content: "OrdersHistory",
        icon: "mdi-history",
      },
      {
        tab: "Listado de Productos",
        content: "ProductList",
        icon: "mdi-clipboard-list",
      },
      {
        tab: "Proveedores",
        content: "Supplier",
        icon: "mdi-clipboard-list",
      },
    ],
    userItems: [{ title: "Salir" }, { title: "Configuración" }],
    isModalVisible: false,
  }),
  methods: {
    menuListClick(item) {
      if (item.title === "Salir") {
        store.dispatch("logOut").then(() => {
          router.push("/login");
        });
      } else if (item.title === "Configuración") {
        this.isModalVisible = true;
      }
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
  beforeRouteEnter(to, from, next) {
    let userRol = JSON.parse(localStorage.getItem("user"));
    if (userRol.rol === "Admin") next();
    else next("login");
  },
};
</script>
